.hover-up {
  transition: transform 0.2s ease-in-out;
}

.hover-up:hover {
  transform: translateY(-5px);
}

.outlineCard {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.outlineCardNoHover {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.outlineCard:hover {
  border-color: #007bff;
}

.outlineCard:hover > h6 {
  color: #007bff !important;
}

.tableCard {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.tableCard:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.outlineCardActive {
  border-color: #007bff !important;
  color: #007bff !important;
}

.activeTextColor {
  color: #007bff !important;
}

span.input-group-text {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.form-control[readonly] {
  background-color: #ffffff;
  cursor: text !important;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.hideScrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
